import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import { LANGS_IRI } from '_lib/langs'
import ageRestrictionsSchema from '_schema/_enums/ageRestriction'

const schema = {
  endpoint: '/api/publishing_house_series',
  access: 'publishingHouseSerie',
  resource: {
    definition: '#PublishingHouseSerie',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          showMoreLink: {
            description: 'Link przycisku "pokaż więcej"',
          },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/wydawnictwo/serie/:slug',
              [LANGS_IRI.EN]: '/publishing-house/series/:slug',
            },
          },
        },
      },
      ageRestriction: {
        description: 'Ograniczenie wiekowe',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: ageRestrictionsSchema.endpoint,
      },
      isShowMoreEnabled: {
        description: 'Włącz przycisk "pokaż więcej"',
        type: FIELD_TYPES.BOOLEAN,
      },
    },
  },
}

export default schema
