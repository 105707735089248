import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { BlockCollection } from 'components/block'
import { EmbeddedCollection } from 'components/embedded'
import { PreviewButton } from 'components/PreviewButton'
import { Form } from 'pages'
import { Title } from 'components/Title'
import { Sidebar } from './sidebar/Sidebar'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'
import { ManualNextRevalidatorButton } from 'components/ManualNextRevalidatorButton'
import { commonConstants } from '_constants'
import { translate } from '_helpers/translate'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/page'
import routes from './routes'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  form: {
    marginTop: 0,
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.profile)
  const { match } = rest

  const iri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const isNotEditable = useCallback(
    resource => !profile.group?.isOpenform && !resource.isEditable,
    [profile.group]
  )

  const history = useHistory()
  const handleSuccess = useCallback(() => {
    dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })

    history.push(routes().index.path)
  }, [history, dispatch])

  const handleSuccessAndStay = useCallback(() => {
    dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
  }, [dispatch])

  const renderHeader = resource => {
    const link = `${process.env.REACT_APP_NEXT_ENTRYPOINT}/${
      resource?.translations[LANGS_IRI.PL].slug
    }`

    return (
      <div>
        <PreviewButton
          resource={resource}
          endpoint={`${schema.endpoint}/preview_token`}
          link={`${schema.resource.properties.translations.properties.slug.routes[
            LANGS_IRI.PL
          ].replace(':slug', resource.translations[LANGS_IRI.PL]?.slug)}`}
        />
        <div style={{ marginBottom: 10 }}>
          <ManualNextRevalidatorButton type="page" uuid={resource.uuid} />
        </div>
        <Title>{translate('T_GENERAL_EDIT')}</Title>
        <div>
          Link:{' '}
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        </div>
      </div>
    )
  }

  const classes = useStyles()

  return (
    <Form
      iri={iri}
      isNotEditable={isNotEditable}
      collectionPath={routes().index.path}
      definitions={definitions}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={(properties, resource, schema) => {
        let modifiedProperties = modifyPropertiesByPage(
          properties,
          resource,
          schema
        )
        return addOpenformProperties(
          profile.group?.isOpenform,
          modifiedProperties,
          resource,
          schema
        )
      }}
      header={renderHeader}
      method="PUT"
      url={iri}
      storeCollectionId={schema.endpoint}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      sidebar={true}
      sidebarTitleAccessor={`translations.${LANGS_IRI.PL}.title`}
      SidebarComponent={Sidebar}
      fieldsFullWidth={false}
      width="100%"
      key={iri}
      classes={{ root: classes.form }}
    >
      <EmbeddedCollection
        endpoint={schema.subresources.menuEntries.endpoint}
        parentIri={iri}
        properties={schema.subresources.menuEntries.properties}
        definitions={definitions}
        definitionSchema={
          definitions[schema.subresources.menuEntries.definition]
        }
        statable={true}
        headerTitle="Submenu"
        titleAccessor={resource =>
          resource.page?.translations[LANGS_IRI.PL]?.title || resource.title
        }
        key={`${iri}-menu-entries`}
      />
      <br />
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        parentIri={iri}
        definitions={definitions}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={`${iri}-blocks`}
      />
    </Form>
  )
}
