import React from 'react'
import { Form } from 'pages'
import { Sidebar } from 'components/resource/sidebar'
import schema from '_schema/publishingHouseSerie'
import routes from './routes'
import { ManualNextRevalidatorButton } from '../../components/ManualNextRevalidatorButton'
import { PreviewButton } from 'components/PreviewButton'
import { LANGS_IRI } from '_lib/langs'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitions={definitions}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor="title"
      SidebarComponent={Sidebar}
      width={600}
      header={resource => (
        <div>
          <PreviewButton
            resource={resource}
            endpoint={`${schema.endpoint}/preview_token`}
            link={`${schema.resource.properties.translations.properties.slug.routes[
              LANGS_IRI.PL
            ].replace(':slug', resource.translations[LANGS_IRI.PL]?.slug)}`}
          />
          <div style={{ marginBottom: 10 }}>
            <ManualNextRevalidatorButton
              type="publishing_house_serie"
              uuid={resource.uuid}
            />
          </div>
        </div>
      )}
    ></Form>
  )
}
