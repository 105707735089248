import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import eventTypeSchema from '_schema/eventType'
import eventCycleSchema from '_schema/eventCycle'
// import { eventHeader as eventHeaderBlock } from '_schema/_blocks/_eventHeader'
import { eventRelated as eventRelatedBlock } from '_schema/_blocks/_eventRelated'
import _block from './_block'
import mediaSchema from '_schema/media'
import ageRestrictionsSchema from '_schema/_enums/ageRestriction'

const ENDPOINT = '/api/events'

const schema = {
  endpoint: ENDPOINT,
  access: 'event',
  resource: {
    definition: '#Event',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          // description: {
          //   type: FIELD_TYPES.TINYMCE,
          // },
          // location: {
          //   type: FIELD_TYPES.TINYMCE,
          // },
          // price: {
          //   type: FIELD_TYPES.TINYMCE,
          // },
          // specializations: {
          //   type: FIELD_TYPES.TINYMCE,
          // },
          // duration: {
          //   type: FIELD_TYPES.TINYMCE,
          // },
          // age: {
          //   type: FIELD_TYPES.TINYMCE,
          // },
          language: {
            description: '[Film] Język',
          },
          genre: {
            description: '[Film] Gatunek',
          },
          director: {
            description: '[Film] Reżyseria',
          },
          actors: {
            description: '[Film] Obsada',
          },
          country: {
            description: '[Film] Kraj',
          },
          ticketCtaTitle: {},
          alt: {},
          // showMoreLink: {
          //   description: 'Link przycisku "pokaż więcej"',
          // },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/wydarzenia/:slug',
              [LANGS_IRI.EN]: '/en/events/:slug',
            },
          },
        },
      },
      infoTitlePolish: {
        description: '[Film] Tytuł polski',
      },
      infoTitleOriginal: {
        description: '[Film] Tytuł oryginalny',
      },
      infoTitleInternational: {
        description: '[Film] Tytuł międzynarodowy',
      },
      infoYear: {
        type: FIELD_TYPES.INTEGER,
        description: '[Film] Rok produkcji',
      },
      type: {
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: `${eventTypeSchema.endpoint}?pagination=false`,
        validate: ['required'],
      },
      cycle: {
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: `${eventCycleSchema.endpoint}?pagination=false`,
        description: 'Cykl',
      },
      iksorisId: {
        type: FIELD_TYPES.IKSORIS,
        endpoint: '/api/iksoris/events',
        titleAccessor: 'name',
        label: 'Wydarzenie',
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'Zdjęcie do kadrów na kafle',
        endpoint: mediaSchema.endpoint,
        thumbs: {
          endpoint: '/api/event_thumbs',
        },
        validate: ['maxSize'],
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: { alt: media.translations[LANGS_IRI.PL].alt },
            [LANGS_IRI.EN]: { alt: media.translations[LANGS_IRI.EN].alt },
          })
        },
      },
      video: {
        description: 'Video',
        type: FIELD_TYPES.FILE,
        endpoint: mediaSchema.endpoint,
        accept: 'video/*',
        restrictManagerMimeType: 'video/',
        validate: ['maxSize'],
      },
      easyReadPdfFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik pdf easy-to-read',
        endpoint: mediaSchema.endpoint,
        accept: 'application/pdf',
        restrictManagerMimeType: 'application/pdf',
        validate: ['maxSize'],
      },
      audioGuideFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik audioprzewodnika',
        endpoint: mediaSchema.endpoint,
        accept: 'audio/*',
        restrictManagerMimeType: 'audio/',
        validate: ['maxSize'],
      },
      audioDescriptionFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik audiodeskrypcji',
        endpoint: mediaSchema.endpoint,
        accept: 'audio/*',
        restrictManagerMimeType: 'audio/',
        validate: ['maxSize'],
      },
      signLanguageFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik PJM',
        endpoint: mediaSchema.endpoint,
        accept: 'video/*',
        restrictManagerMimeType: 'video/',
        validate: ['maxSize'],
      },
      ageRestriction: {
        description: 'Ograniczenie wiekowe',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: ageRestrictionsSchema.endpoint,
      },
      // isShowMoreEnabled: {
      //   description: 'Włącz przycisk "pokaż więcej"',
      //   type: FIELD_TYPES.BOOLEAN,
      // },
    },
  },
  subresources: {
    images: {
      endpoint: '/api/event_images',
      definition: '#EventImage',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            alt: {
              description: 'T_GENERAL_ALT',
            },
          },
        },
        media: {
          type: 'image',
          description: 'Zdjęcie',
          endpoint: mediaSchema.endpoint,
          validate: ['maxSize'],
          thumbs: {
            endpoint: '/api/event_image_thumbs',
          },
        },
      },
    },
    block: {
      endpoint: '/api/event_blocks',
      definition: '#EventBlock',
      types: [
        ..._block('event').types,
        // eventHeaderBlock(),
        eventRelatedBlock(true, 'event'),
      ],
    },
    relatedEvents: {
      endpoint: '/api/event_related_events',
      definition: '#EventRelatedEvent',
      properties: {
        event: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: 'title',
          searchProperty: 'title',
          endpoint: ENDPOINT,
          validate: ['required'],
        },
      },
    },
  },
}

export default schema
