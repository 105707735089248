export const BLOCK_TYPES_IRI = {
  TEXT: '/api/block_types/TEXT',
  TEXT_WITH_PHOTO: '/api/block_types/TEXT_WITH_PHOTO',
  PHOTO: '/api/block_types/PHOTO',

  NEWEST_NEWS: '/api/block_types/NEWEST_NEWS',
  CALENDAR: '/api/block_types/CALENDAR',
  CURRENT_EXHIBITIONS: '/api/block_types/CURRENT_EXHIBITIONS',
  BLOG_ENTRIES: '/api/block_types/BLOG_ENTRIES',
  MAGAZINE_ARTICLES: '/api/block_types/MAGAZINE_ARTICLES',
  MAGAZINE_TWO_ARTICLES: '/api/block_types/MAGAZINE_TWO_ARTICLES',

  MUSEUM_BRANCHES: '/api/block_types/MUSEUM_BRANCHES',

  AUDIO_PLAYER: '/api/block_types/AUDIO_PLAYER',
  VIDEO_PLAYER: '/api/block_types/VIDEO_PLAYER',

  LOGO_GROUPS: '/api/block_types/LOGO_GROUPS',
  CONTENT_MODULES: '/api/block_types/CONTENT_MODULES',
  CONTENT_SLIDER: '/api/block_types/CONTENT_SLIDER',
  TEXT_ENTRIES: '/api/block_types/TEXT_ENTRIES',
  ARTISTS: '/api/block_types/ARTISTS',
  GLOSSARY: '/api/block_types/GLOSSARY',
  ACCORDIONS: '/api/block_types/ACCORDIONS',
  CARDS: '/api/block_types/CARDS',
  FILES: '/api/block_types/FILES',
  RELATED_LINKS: '/api/block_types/RELATED_LINKS',

  IMAGE_CAROUSEL: '/api/block_types/IMAGE_CAROUSEL',
  IMAGE_GALLERY: '/api/block_types/IMAGE_GALLERY',
  IMAGES: '/api/block_types/IMAGES',

  EVENT_HEADER: '/api/block_types/EVENT_HEADER',
  EVENT_RELATED: '/api/block_types/EVENT_RELATED',
  EVENT_RELATED_PAGE: '/api/block_types/EVENT_RELATED_PAGE',

  EXHIBITION_RELATED_EVENTS: '/api/block_types/EXHIBITION_RELATED_EVENTS',
  EXHIBITION_RELATED_EXHIBITIONS:
    '/api/block_types/EXHIBITION_RELATED_EXHIBITIONS',
  EXHIBITION_EXHIBITS: '/api/block_types/EXHIBITION_EXHIBITS',
  EXHIBITION_ARTISTS: '/api/block_types/EXHIBITION_ARTISTS',
  EXHIBITION_IMAGES: '/api/block_types/EXHIBITION_IMAGES',
  EXHIBITION_IMAGE_CAROUSEL: '/api/block_types/EXHIBITION_IMAGE_CAROUSEL',
  EXHIBITION_IMAGE_GALLERY: '/api/block_types/EXHIBITION_IMAGE_GALLERY',
  EXHIBITION_SINGLE_EXHIBIT: '/api/block_types/EXHIBITION_SINGLE_EXHIBIT',

  COLLECTION_PREVIEW: '/api/block_types/COLLECTION_PREVIEW',

  PUBLICATIONS: '/api/block_types/PUBLICATIONS',
  AUTHORS: '/api/block_types/AUTHORS',

  FORMSET: '/api/block_types/FORMSET',
  NEWSLETTER: '/api/block_types/NEWSLETTER',
  JOB_OFFERS: '/api/block_types/JOB_OFFERS',
  ARCHIVE_EVENTS: '/api/block_types/ARCHIVE_EVENTS',
  TYPED_EVENTS: '/api/block_types/TYPED_EVENTS',
  RELATED_NEWS: '/api/block_types/RELATED_NEWS',
  TEAM: '/api/block_types/TEAM',

  EVENT_CYCLE: '/api/block_types/EVENT_CYCLE',
  EVENTS: '/api/block_types/EVENTS',
  CINEMA_CALENDAR: '/api/block_types/CINEMA_CALENDAR',
}
