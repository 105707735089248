import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import { LANGS_IRI } from '_lib/langs'
import _block from './_block'
import { eventRelated } from './_blocks/_eventRelated'
import ageRestrictionsSchema from '_schema/_enums/ageRestriction'
import branchSchema from '_schema/branch'

const ENDPOINT = '/api/pages'

const schema = {
  endpoint: ENDPOINT,
  clone_endpoint: `${ENDPOINT}/clone`,
  access: 'page',
  resource: {
    definition: '#Page',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          showMoreLink: {
            description: 'Link przycisku "pokaż więcej"',
          },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/:slug',
              [LANGS_IRI.EN]: '/en/:slug',
            },
          },
        },
      },
      ageRestriction: {
        description: 'Ograniczenie wiekowe',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: ageRestrictionsSchema.endpoint,
      },
      branch: {
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: `${branchSchema.endpoint}?pagination=false`,
      },
      iksorisId: {
        type: FIELD_TYPES.IKSORIS,
        endpoint: '/api/iksoris/events',
        titleAccessor: 'name',
        label1: 'Wydarzenie',
      },
      isShowMoreEnabled: {
        description: 'Włącz przycisk "pokaż więcej"',
        type: FIELD_TYPES.BOOLEAN,
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks',
      definition: '#PageBlock',
      types: [..._block('page').types, eventRelated(false, 'page')],
    },
    menuEntries: {
      endpoint: '/api/page_menu_entries',
      definition: '#PageMenuEntry',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {},
            link: {},
          },
        },
        page: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: `translations.${LANGS_IRI.PL}.title`,
          searchProperty: 'translations.title',
          endpoint: ENDPOINT,
        },
      },
    },
  },
}

export default schema
