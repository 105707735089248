import React from 'react'
import { Form } from 'pages'
import { Sidebar } from 'components/resource/sidebar'
import { BlockCollection } from 'components/block'
import { EmbeddedCollection } from 'components/embedded'
import { ManualNextRevalidatorButton } from 'components/ManualNextRevalidatorButton'
import { PreviewButton } from 'components/PreviewButton'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/event'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitions={definitions}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor="title"
      SidebarComponent={Sidebar}
      width={600}
      header={resource => (
        <div>
          <PreviewButton
            resource={resource}
            endpoint={`${schema.endpoint}/preview_token`}
            link={`${schema.resource.properties.translations.properties.slug.routes[
              LANGS_IRI.PL
            ].replace(':slug', resource.translations[LANGS_IRI.PL]?.slug)}`}
          />
          <div style={{ marginBottom: 10 }}>
            <ManualNextRevalidatorButton
              type="event"
              uuid={resource.uuid}
            />
          </div>
        </div>
      )}
    >
      <EmbeddedCollection
        endpoint={schema.subresources.images.endpoint}
        parentIri={iri}
        properties={schema.subresources.images.properties}
        definitions={definitions}
        definitionSchema={definitions[schema.subresources.images.definition]}
        statable={true}
        headerTitle="Zdjęcia"
        titleAccessor={`translations.${LANGS_IRI.PL}.title`}
        key={`${iri}-images`}
      />
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        parentIri={iri}
        definitions={definitions}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        statable={true}
        key={`${iri}-blocks`}
      />
      <EmbeddedCollection
        endpoint={schema.subresources.relatedEvents.endpoint}
        parentIri={iri}
        properties={schema.subresources.relatedEvents.properties}
        definitions={definitions}
        definitionSchema={
          definitions[schema.subresources.relatedEvents.definition]
        }
        statable={true}
        headerTitle="Wydarzenia powiązane"
        titleAccessor="event.title"
        key={`${iri}-related-events`}
      />
    </Form>
  )
}
