import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import { LANGS_IRI } from '_lib/langs'

const FILES_ENDPOINT = '/api/<TYPE>_block_files'

export const files = type => ({
  name: BLOCK_TYPES_IRI.FILES,
  label: 'Załączniki',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    files: {
      description: 'Pliki',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: FILES_ENDPOINT.replace('<TYPE>', type),
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              description: 'Tytuł',
              type: FIELD_TYPES.STRING,
            },
            media: {
              type: FIELD_TYPES.FILE,
              description: 'Plik',
              endpoint: mediaSchema.endpoint,
              validate: ['maxSize'],
              handleFileManagerMetadata: (setValue, media) => {
                setValue('injectTranslations', {
                  [LANGS_IRI.PL]: {
                    title: media.translations[LANGS_IRI.PL].title,
                  },
                  [LANGS_IRI.EN]: {
                    title: media.translations[LANGS_IRI.EN].title,
                  },
                })
              },
            },
          },
        },
        media: {
          type: FIELD_TYPES.FILE,
          description: 'Plik',
          endpoint: mediaSchema.endpoint,
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                title: media.translations[LANGS_IRI.PL].title,
              },
              [LANGS_IRI.EN]: {
                title: media.translations[LANGS_IRI.EN].title,
              },
            })
          },
        },
      },
    },
  },
})
