import React, { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'pages'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { commonConstants } from '_constants'
import parentSchema from '_schema/event'
import schema from '_schema/eventTerm'
import routes from 'pages/Events/routes'

export const New = ({ schema: definitions, ...rest }) => {
  const { match, location } = rest

  const dispatch = useDispatch()

  const onFetchSuccess = useCallback(
    resource => {
      dispatch({
        type: commonConstants.ADD_CUSTOM_BREADCRUMBS,
        payload: {
          path: location.pathname,
          breadcrumbs: [
            {
              path: routes().index.path,
              title: 'Wydarzenia',
            },
            {
              path: routes().edit.path.replace(':id', match.params.pid),
              title: resource.title,
            },
            {
              path: routes().terms_index.path.replace(':pid', match.params.pid),
              title: routes().terms_index.subtitle,
            },
            {
              path: location.pathname,
              title: routes().terms_new.subtitle,
            },
          ],
        },
      })
    },
    [dispatch, location.pathname, match.params.pid]
  )

  const parentIri = useMemo(
    () => `${parentSchema.endpoint}/${match.params.pid}`,
    [match.params.pid]
  )

  const [state, setState] = useResourceState()

  const { resource: parentResource } = state

  useResourceFetch(
    parentIri,
    parentResource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    null,
    null,
    onFetchSuccess
  )

  return (
    <Form
      definitions={definitions}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().terms_index.path.replace(
        ':pid',
        match.params.pid
      )}
      editPath={routes().terms_edit.path.replace(':pid', match.params.pid)}
      storeCollectionId={schema.endpoint}
      width={600}
      defaultData={{ parent: parentIri }}
    />
  )
}
