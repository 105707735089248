import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/event_terms',
  resource: {
    definition: '#EventTerm',
    properties: {
      dateFrom: {
        type: FIELD_TYPES.DATE,
        validate: ['required'],
      },
      dateTo: {
        type: FIELD_TYPES.DATE,
      },
      timeFrom: {
        type: FIELD_TYPES.TIME,
      },
      timeTo: {
        type: FIELD_TYPES.TIME,
      },
    },
  },
}

export default schema
