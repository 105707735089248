import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import { LANGS_IRI } from '../_lib/langs'
import ageRestrictionsSchema from '_schema/_enums/ageRestriction'

const schema = {
  endpoint: '/api/publishing_house_authors',
  access: 'publishingHouseAuthor',
  resource: {
    definition: '#PublishingHouseAuthor',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          // title: {
          //   description: 'Imię i nazwisko',
          // },
          description: {
            type: FIELD_TYPES.TINYMCE,
            description: 'Biografia',
          },
          // showMoreLink: {
          //   description: 'Link przycisku "pokaż więcej"',
          // },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          // slug: {
          //   type: FIELD_TYPES.SLUG,
          //   routes: {
          //     [LANGS_IRI.PL]: '/wydawnictwo/autorzy/:slug',
          //     [LANGS_IRI.EN]: '/publishing-house/authors/:slug',
          //   },
          // },
        },
      },
      firstName: {
        description: 'Imię',
      },
      lastName: {
        description: 'Nazwisko',
      },
      slug: {
        type: FIELD_TYPES.SLUG,
        description: 'Slug (generowany automatycznie z imienia i nazwiska)',
        routes: {
          [LANGS_IRI.PL]: '/wydawnictwo/autorzy/:slug',
          [LANGS_IRI.EN]: '/publishing-house/authors/:slug',
        },
      },
      ageRestriction: {
        description: 'Ograniczenie wiekowe',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: ageRestrictionsSchema.endpoint,
      },
      // isShowMoreEnabled: {
      //   description: 'Włącz przycisk "pokaż więcej"',
      //   type: FIELD_TYPES.BOOLEAN,
      // },
    },
  },
}

export default schema
