import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import eventCycleSchema from '_schema/eventType'

export const eventCycle = () => ({
  name: BLOCK_TYPES_IRI.EVENT_CYCLE,
  label: 'Cykl wydarzeń',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    eventCycle: {
      type: FIELD_TYPES.RESOURCE,
      description: 'Cykl',
      titleAccessor: 'title',
      endpoint: `${eventCycleSchema.endpoint}?pagination=false`,
    },
  },
})
